.missing-info{
    background: rgb(255,255,255);
    border-radius: 4px;
    bottom: 80px;
    box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05);
    padding: 16px;
    position: fixed;
    right: 16px;
    z-index: 1000;
}
.title-wrapper {
    align-items: center;
    display: flex;
    
}
.title-wrapper div {
    color: rgb(0, 0, 0, .85);
    margin-left: 16px;
}
.title-wrapper img:last-child {
    margin-left: auto;
    margin-top: -4;
}

.missing-info-ul {
    margin-bottom: 4px;
    margin-right: 24px;
    margin-top: 8px;
    padding-left: 58px;
}