.content-routes{
    flex-grow: 1;
    height: 100%;
    overflow: auto;
    padding-top: 6px;
}

html, body, #root { 
    font-family: 'Source Sans Pro', sans-serif !important
}

#googleSignInButtonDiv{
    margin: auto;
    /* margin-top: 50% */
    vertical-align: middle;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.login-wrapper {
    height: 100vh;
    position: relative;
}