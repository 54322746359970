.modal-buttons{
    align-items: center;
    display: flex;
}

.confirmation-modal{
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    background: rgb(255,255,255);
    border-radius: 4px;
    box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05);
    padding: 16px;
    position: fixed;
    z-index: 1000;
}

.modal-buttons button{
    margin: 3px;
    border-radius: 5px;
    outline: none !important;
    border: none !important;
    width: 150px;
    height: 50px;
    
}

.modal-title{
    display: flex;
    text-align: center;
}

.sample-capture-modal{
    background: rgba(0,0,0,.45);
    bottom: 0;
    left: 0;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;
}

.modal-body-text{
    font-size: 14px;
    /* font-family: 'Source Sans Pro', sans-serif */;
}