.sample-capture-action-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.sample-capture-action-wrapper>div:last-child {
    margin-right: 0px !important;
}

@media only screen and (max-width: 767px) {
    .sample-capture-action-wrapper {
        flex-direction: column
    }

    .sample-capture-action-wrapper div {
        margin-bottom: 16px;
        margin-right: 0;
    }

    .sample-capture-action-wrapper>div:last-child {
        margin-bottom: 0;
    }
}

.has-error label {
    color: rgb(255, 77, 79);
}

.has-error select {
    border-color: rgb(255, 77, 79);
    border-radius: 4px;
}

.has-error select:focus {
    border-color: rgb(255, 77, 79) !important;
    outline: 0 !important;
}

.lock-wrapper {
    display: flex;
}

.lock-wrapper button {
    height: 35px;
    justify-content: center;
    padding: 0 8px;
    width: 100%;
    border: none;
}

.lock-wrapper button:first-child:not(:only-child) {
    margin-right: 16px;
}

.lock-wrapper.with-input {
    margin-top: 12px;
}

.sample-capture-lab-number-wrapper{
    display: block;
    width: 100%;
}

.sample-capture-lab-number-wrapper div{
    flex: 1;
}

.lab-range div:first-child:not(:only-child){
    margin-right: 12px;
}

.advance-options-button{
    border: none !important;
    background-color: rgb(248, 248, 248);
}

.auto-control{
    margin-right: 8px;
    margin-left: 8px;
    height: 40px;
    width: 200px;
    justify-content: center;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 4px;
}

select, input{
    height: 40px;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 4px;
}

.lab-number-div{
    width: 100% !important;
    display: block !important;
}

.lab-number-input{
    width: 100% !important;
}

.lab-range{
    display: flex;
}

.sample-option-label{
    font-size: 14px;
}