.sample-capture-image-grid-autopics {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .sample-capture-image-grid-autopics {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

@media only screen and (max-width: 767px) {
    .sample-capture-image-grid-autopics {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

.sample-capture-image-grid {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .sample-capture-image-grid {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media only screen and (max-width: 767px) {
    .sample-capture-image-grid {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

.capture-wrapper {
    background: rgb(250, 250, 250);
    display: flex;
    flex-direction: column;
}

.capture-wrapper>img {
    border: solid 1px rgb(232, 232, 232);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    height: 200px;
    object-fit: contain;
    width: 100%;
}

.icon-wrapper {
    background: rgb(250, 250, 250);
    border: solid 1px rgb(232, 232, 232);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: none;
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    width: 100%;
}

.icon-wrapper div {
    align-items: center;
    border-right: solid 1px rgb(232, 232, 232);
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 8px 0;
}

.icon-wrapper div:last-child() {
    border-right: none !important;
}

.cam-preview-div {
    text-align: right;
}

.file-upload {
    align-items: center;
    background: rgb(250, 250, 250);
    border: 1px dashed rgb(216, 216, 216);
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 249px;
    justify-content: center;
    width: 100%;
}

.has-error {
    border-color: rgb(255,77,79);
}

.icon {
    margin-bottom: 2px;
}

.auto-grid-container {
    display: grid;
    gap: 5px;
    grid-template-columns: auto auto;
}